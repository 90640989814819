<template>
  <div>
    <CCards
        icon="fa fa-user"
        caption="Tipo de Rol"
        btn_name="ocultar"
        btn_name1="ocultar"
        @show_modal="mtd_open_modal"
        :button="cp_button_busqueda"
    >
        <CRow>
            <CCol sm="6">
            <cSelectForm
                label="Seleccione Tipo Rol"
                :options="data_roles"
                placeholder="Escoja un Tipo Rol"
                :value.sync="form_add_role.role_id"   
                @change="form_selected"          
            />
            </CCol>
            <CCol sm="6">
                <button type="button" :disabled="vue.estado == 1 ? true : false" style="margin-top:5.5%;width:25%" :class="btnClasses" @click="loadTable"><i class="fas fa-search"></i>&nbsp; Buscar</button>
            </CCol>
        </CRow>
    </CCards>

    <CRow v-if="vue.exp">
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          striped
          border
          small
          fixed
          :caption="vue.texto"
          icon="fas fa-users"
          btn_name="Usuario"
          :showhide_buttons="vue.showhide"
          @show_modal="mtd_open_modal"
          @action_pass="mtd_pass"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Nombre"
            placeholder="Digite Nombre"
            v-model="users.name"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            label="Apellido"
            placeholder="Digite Apellido"
            v-model="users.last_name"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            label="DNI"
            placeholder="Digite DNI"
            v-model="users.dni"
          />
          <CInput
            type="hidden"
            v-model="users.document_type"
          />
          <CInput
            type="hidden"
            v-model="users.rol_id"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CInput
            label="Email"
            placeholder="Digite Email"
            v-model="users.email"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal password -->
    <CModalForm
      :size="'md'"
      :title="modal.title"
      :button="cp_button_pass"
      :show.sync="modal.modal_form_pass"
      @mtd_action="mtd_action_pass"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            type="password"
            label="Nueva Contraseña"
            placeholder="Digite Contraseña"
            v-model="pass.password"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Usuario"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>
  </div>
</template>


<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import cSelectForm from '../../components/shared/inputs/cSelect.vue';
import CCards from "../../components/shared/containers/Cards.vue";
import { mapActions } from "vuex";
import { bus } from '../../main'

export default {
  components: { CTableWrapper, CModalForm, cModalDelete, cSelectForm, CCards },
  data() {
    return {
      prefix: "users",
      vue:{
          exp: false,
          texto: '',
          estado: 1,
          showhide: Array // dato para los estados de los botones
      },
      fields: [
        "Id",
        "Nombre",
        "Apellidos",
        "Email" // resource
      ],
      data: [],
      data_roles:[],
      form_add_role:{
        role_id:"",
        permission_id:"",
        state:1
      },
      modal: {
        action: "",
        title: "",
        modal_form: false,
        modal_form_pass: false,
      },
      pass: {
        id: "",
        password: "",
      },
      users: {
        id: "",
        name: "",
        last_name: "",
        dni: "",
        document_type: "",
        rol_id: "",
        email: "",
        status: ""
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  created() {
    this.mtd_getdata_typespermissions();
  },
  computed: {
    cp_button: function () {
      if (this.users.name != "" && this.users.last_name) return false;
      return true;
    },
    cp_button_busqueda: function () {
      if (this.form_add_role.role_id != "") return false;
      return true;
    },
    cp_button_pass: function () {
      if (this.form_add_role.role_id != "") return false;
      return true;
    },
    btnClasses () {
      return [`btn btn-${this.color || 'primary'}`]
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    form_selected: function(){
      this.vue.estado = 0;
    },
    mtd_getdata_typespermissions: function () {
      this.get({
        url: this.$store.getters.get__url + "/rol",
        token: this.$store.getters.get__token,
      })
      .then((response) => {
        this.data_roles = response.data_roles;

        // Enviar Estados para los Botones
          this.vue.showhide = [{"btn_ver": 0, "btn_pass": 1, "btn_edit": 1, "btn_del": 1 }];
      })
      .catch((errors) => {});
    },
    mtd_getdata_captionrol: function (id) {
      this.get({
        url: this.$store.getters.get__url + "/rol/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.vue.texto = "Lista de Usuarios: " + response.name;
        })
        .catch((errors) => {});
    },
    mtd_getdata_usuariosxrol: function (id) {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix + "/" + id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
            this.data = response.data;
        })
        .catch((errors) => {});
    },
    loadTable: function(){
        let id = this.form_add_role.role_id;
        if (id!=0) {
            this.vue.exp = true;
        }else{
            this.vue.exp = false;
        }

        this.mtd_getdata_captionrol(id);
        this.mtd_getdata_usuariosxrol(id);
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Usuario")
        : (this.modal.title = "Editar Usuario");
      let rol_id = this.form_add_role.role_id;
      action == "store" 
        ? (
            this.users= {
              id: "",
              name: "",
              last_name: "",
              dni: "",
              document_type: "DNI",
              rol_id: rol_id,
              email: "",
              status: "",
            }
          )
        : this.users = this.users;
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.users,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            this.data.push(response[0]);
            message = "REGISTRADO CORRECTAMENTE";

            let rol_id = this.form_add_role.role_id;
            // this.mtd_getdata_captionrol(rol_id);
            // this.mtd_getdata_usuariosxrol(rol_id);

          } else {
            this.data.forEach((element) => {
              if (element.Id == response[0].Id) {
                element.Nombre = response[0].Nombre;
                element.Apellidos = response[0].Apellidos;
                element.Documento = response[0].Documento;
                element.rol_id = response[0].rol_id;
                element.Email = response[0].Email;
              }
              message = "EDITADO CORRECTAMENTE";

              let rol_id = this.form_add_role.role_id;
              // this.mtd_getdata_captionrol(rol_id);
              // this.mtd_getdata_usuariosxrol(rol_id);
            });
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
            last_name: "",
            document_type: "",
            dni: "",
            rol_id: "",
            email: "",
          };
          bus.$emit('alert', { 
            color:color,
            message:message
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
          bus.$emit('alert', { 
            color:'danger',
            message:errors
          });
        });
    },
    mtd_pass: function(id){
      this.modal.modal_form_pass = true;
      this.modal.title = "Cambiar Contraseña"

      this.get({
        url: this.$store.getters.get__url +"/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token
      }).then((response) => {
        this.pass = response;
      }).catch((errors) => {});
    },
    mtd_action_pass: function(){
      let color = "success";
      let message = "";
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/new_password",
        token: this.$store.getters.get__token,
        params: this.pass
      }).then((response)=> {
        message = "REGISTRADO CORRECTAMENTE.";

        this.modal = {
          action: "",
          title: "",
          modal_form: false,
          id: "",
          password: "",
        };
        bus.$emit('alert', { 
          color:color,
          message:message
        });
      }).catch((errors) => {
          bus.$emit('alert', { 
            color:'danger',
            message:errors
          });
      });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
          token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.users = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete= {
            boo: false,
            item: [],
          };
          bus.$emit('alert', { 
            color:'success',
            message:'ELIMINADO CORRECTAMENTE'
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>